<template>
  <el-row id="home" class="home">
    <!-- 版图网络 -->
    <el-row class="row">
      <el-row class="row-flex" type="flex" justify="center">
        <el-col :span="20">
          <feature-head text="经典案例" class="show-sm-and-up" />
          <h1 class="show-sm-and-down header">经典案例</h1>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="20">
          <el-row class="row-flex" type="flex" justify="space-between" align="start">
            <el-col :xs="24" :sm="10" :lg="5">
              <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                <img src="@/assets/images/contenticon/shenghuo.png" alt />
                <h4>在芜湖</h4>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
              <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                <img src="@/assets/images/contenticon/jiankong.png" alt />
                <h4>产品追踪回溯</h4>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
              <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                <img src="@/assets/images/contenticon/xinyong.png" alt />
                <h4>信用芜湖</h4>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
              <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                <img src="@/assets/images/contenticon/gupiao.png" alt />
                <h4>慧选股票</h4>
              </el-card>
            </el-col>
          </el-row>
          <el-row class="row-flex" type="flex" justify="space-between" align="start">
            <el-col :xs="24" :sm="10" :lg="5">
                <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                  <img src="@/assets/images/contenticon/shop.png" alt />
                  <h4>欣源商贸</h4>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
                <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                  <img src="@/assets/images/contenticon/trip.png" alt />
                  <h4>好行网</h4>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
                <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                  <img src="@/assets/images/contenticon/smart.png" alt />
                  <h4>智慧工地管控平台</h4>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="10" :lg="5">
                <el-card shadow="hover" class="card" :body-style="{padding: '10px 0'}">
                  <img src="@/assets/images/contenticon/test-and-repair.png" alt />
                  <h4>泄漏检测与修复</h4>
                </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <!-- 版图优势 -->
    <el-row class="row advance">
      <!-- advance-header -->
      <el-row class="row-flex" type="flex" justify="center">
        <el-col :span="20">
          <feature-head text="版图优势" class="show-sm-and-up" />
          <h1 class="header show-sm-and-down">版图优势</h1>
        </el-col>
      </el-row>
      <!-- advance-content -->
      <el-row>
        <!--    advance-content-top    -->
        <el-row class="row-flex" type="flex" justify="center">
          <el-col :span="20">
            <el-row class="row-flex" type="flex" justify="space-between">
              <el-col class="advance-item" :xs="24" :sm="24" :md="5">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/team.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>专业的团队</strong>
                    <p>拥有众多专业的开发人员</p>
                  </el-col>
                </el-row>
              </el-col>
              <el-col class="advance-item" :xs="24" :sm="24" :md="5">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/personal.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>个性化服务</strong>
                    <p>提供专业、持久的个性化服务</p>
                  </el-col>
                </el-row>
              </el-col>
              <el-col class="advance-item" :xs="24" :sm="24" :md="4">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/gaoxiao_1.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>高效的开发效率</strong>
                    <p>快速、高效的完成开发</p>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!--    advance-content-bottom    -->
        <el-row class="row-flex" type="flex" justify="center">
          <el-col :span="20">
            <el-row class="row-flex" type="flex" justify="space-between">
              <el-col class="advance-item" :xs="24" :sm="24" :md="5">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/create.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>勇于创新的理念</strong>
                    <p>坚持勇于创新的理念</p>
                  </el-col>
                </el-row>
              </el-col>
              <el-col class="advance-item" :xs="24" :sm="24" :md="5">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/chengxin.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>诚信的态度</strong>
                    <p>以诚信的态度对待每一位客户</p>
                  </el-col>
                </el-row>
              </el-col>
              <el-col class="advance-item" :xs="24" :sm="24" :md="4">
                <el-row type="flex" justify="start">
                  <div>
                    <img src="@/assets/images/contenticon/quantity.png" alt />
                  </div>
                  <el-col :md="19" class="advance-desc">
                    <strong>超高的开发质量</strong>
                    <p>以超高的质量完成每一项业务</p>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import FeatureHead from "@/components/common/FeatureHead";
export default {
  name: "Home",
  components: {
    FeatureHead,
  },
};
</script>

<style scoped>
.home .swiper-area {
  padding: 0;
}

.home .row {
  overflow: hidden;
}

.home .row .card {
  text-align: center;
  border: none;
  transition: transform ease .5s;
}

.home .row .row-flex {
  flex-wrap: wrap;
}

.home .header {
  margin: 0;
  padding: 45px 0;
  text-align: center;
  font-weight: 200;
  font-size: 28px;
  color: #7c8394;
}

.home .card h4 a {
  color: #000;
}

.home .card img {
  width: 90px;
}

.home .card p {
  color: #606266;
  font-size: 14px;
  font-family: "Microsoft Yahei";
  line-height: 1.5;
}

.home .card:hover {
  transform: translateY(-10px);
}

.home .card:hover h4 {
  color: rgb(34, 120, 217);
}

.home .advance .advance-item {
  margin: 0 0 15px 0;
  color: #606266;
  font-size: 14px;
}

.home .advance .advance-item strong {
  font-weight: 400;
}

.home .advance .advance-item p {
  font-size: 14px;
}

.home .advance img {
  margin-right: 7px;
  width: 60px;
  vertical-align: middle;
}

.home .advance .advance-desc {
  padding: 10px 0;
}
</style>
